import { useEffect, useRef, useState } from 'react';

import metricsService from '../services/metrics';
import providersService from '../services/providers';
import useGandalfPostAuth from '../hooks/useGandalfPostAuth';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import { AppURL } from '../constants/urls';
import { IMPRESSIONS } from '../constants/metrics';
import { redirectToLXPAuth } from '../services/auth';
import EulaCheck from '../components/postAuth/EulaCheck';
import AccountLinkConsent from '../components/postAuth/AccountLinkConsent';
import VerifyEmail from '../components/postAuth/VerifyEmail';
import LearnerConsentOnMerge3p from '../components/postAuth/LearnerConsentOnMerge3p';
import {
  GandalfUser,
  IdProvider,
  PostAuthComponentParams,
} from '../types/auth';
import { AppConfig } from '../types/app';
import SignInUnauthorized from '../components/SignInUnauthorized';
import SignInFailed from '../components/SignInFailed';
import UnauthorizedError from '../errors/UnauthorizedError';
import { IDP_URL_PARAM, URL_PARAMS } from '../constants/auth';
import MissingEmailError from '../errors/MissingEmailError';
import MissingEmail from '../components/MissingEmail';
import { useExplicitAmplifyConfig } from '../hooks/useAmplifyConfig';
import useIdpFromState from '../hooks/useIdpFromState';
import { rumService } from '../services/rum';
import { getInitialLocaleSelection } from '../contexts/LocaleContextProvider';
import { DEFAULT_LOCALE } from '../constants/locales';
import { AccountLinkWarning } from '../components/postAuth/AccountLinkWarning';
import { GandalfSession } from '../components/postAuth/GandalfSession';
import LwaNewUserPrevention from '../components/LwaNewUserPrevention';
import { IdPs, isStandardProvider } from '../constants/providers';
import React from 'react';
import IdentityConsent from '../components/postAuth/IdentityConsent';
import ProfileDeactivated from '../components/postAuth/ProfileDeactivated';
import SignInSwitchNeeded from '../components/postAuth/SignInSwitchNeeded';
import SkipPostAuthElti from '../components/postAuth/SkipPostAuthElti';
import {
  GetPublicIdpDetailsOutput,
  queryApi,
} from '../hooks/useGetPublicIdpDetails';
import ProfileAndPreferences from '../components/postAuth/ProfileAndPreferences';
import { localStorageAdapter } from '../services/storage';

export enum PostAuthFlows {
  ProfileDeactivated = 'ProfileDeactivated',
  LwaNewUserPrevention = 'LwaNewUserPrevention',
  ProfileAndPreferences = 'ProfileAndPreferences',
  EULA = 'EULA',
  AccountLinkConsent = 'Account Link Consent',
  AccountLinkWarning = 'AccountLinkWarning',
  EmailVerification = 'Email Verification',
  LearnerConsentOnOrgInvite = 'Learner Consent on Org Invite',
  GandalfSession = 'Gandalf Session',
  LearnerConsentOnMerge3p = 'Learner Consent On Merge 3P',
  SignInSwitchNeeded = 'SignInSwitchNeeded',
  SkipPostAuthElti = 'SkipPostAuthElti',
}

type PostAuthComponent = {
  type: PostAuthFlows;
  component: React.FC<PostAuthComponentParams>;
};

/**
 * List all post auth flow components in the order they should be displayed.
 */
const postAuthFlows: PostAuthComponent[] = [
  { type: PostAuthFlows.GandalfSession, component: GandalfSession },
  { type: PostAuthFlows.SkipPostAuthElti, component: SkipPostAuthElti },
  { type: PostAuthFlows.ProfileDeactivated, component: ProfileDeactivated },
  { type: PostAuthFlows.SignInSwitchNeeded, component: SignInSwitchNeeded },
  { type: PostAuthFlows.LwaNewUserPrevention, component: LwaNewUserPrevention },
  { type: PostAuthFlows.EmailVerification, component: VerifyEmail },
  { type: PostAuthFlows.LearnerConsentOnOrgInvite, component: IdentityConsent },
  { type: PostAuthFlows.AccountLinkConsent, component: AccountLinkConsent },
  { type: PostAuthFlows.EULA, component: EulaCheck },
  { type: PostAuthFlows.AccountLinkWarning, component: AccountLinkWarning },
  {
    type: PostAuthFlows.LearnerConsentOnMerge3p,
    component: LearnerConsentOnMerge3p,
  },
  {
    type: PostAuthFlows.ProfileAndPreferences,
    component: ProfileAndPreferences,
  },
];

/**
 * Replaces current URL state to enable "back" and reload in the browser.
 */
function replaceUrlWithReloadSafeParams(searchParams: string) {
  const refreshParams = new URLSearchParams(searchParams);
  // Remove the identity_provider to not trigger preferred IDP flow.
  refreshParams.delete(IDP_URL_PARAM);
  window.history.replaceState(
    {},
    document.title,
    `${AppURL.Login}?${refreshParams.toString()}`
  );
}

function AuthResponse({ config }: { config: AppConfig }) {
  const [flowIndex, setFlowIndex] = useState(0);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [provider, setProvider] = useState<IdProvider | undefined>(undefined);
  const [consentAccepted, setConsentAccepted] = useState<boolean | undefined>(
    undefined
  );
  const [idpDetails, setIdpDetails] = useState<
    GetPublicIdpDetailsOutput | undefined
  >(undefined);
  const idpFromState = useIdpFromState();
  // Note: The first call to `Auth.configure` from this page will trigger a token exchange.  So, we have to ensure the
  // correct IDP configuration is provided.
  useExplicitAmplifyConfig(config, provider || idpFromState);
  const {
    user,
    error: postAuthError,
    customState,
  } = useGandalfPostAuth(false, config);
  const metricsPublisher = useRef(metricsService.getPublisher('AuthResponse'));
  const [authComponentError, setError] = useState(undefined);
  const error = postAuthError || authComponentError;

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor(IMPRESSIONS, 1);
  }, []);

  useEffect(() => {
    const handleGlobalError = (event) => {
      if (
        event.error instanceof ReferenceError &&
        event.error.message.includes('process is not defined')
      ) {
        // eslint-disable-next-line no-console
        console.error(
          'Global handler caught "process is not defined" error:',
          event.error.message
        );
        // eslint-disable-next-line no-console
        console.error('Stack trace:', event.error.stack);
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener('error', handleGlobalError);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', handleGlobalError);
    };
  }, []);

  // Await auth flow and custom OIDC state, the state contains the persistes search params.
  // When this is received we can start post auth flows and then continue redirecting to
  // the LXP specific auth.
  useEffect(() => {
    if (!customState) return;
    const idpConfig: IdProvider = JSON.parse(customState);
    const searchParams = new URL(idpConfig.url).search;

    const urlSearchParams = new URLSearchParams(searchParams);
    const idp = urlSearchParams.get(IDP_URL_PARAM);
    // Update the URL
    replaceUrlWithReloadSafeParams(searchParams);
    // Get the provider. Note, it will use values from the URL.
    const provider = providersService.transformNameToIdProvider(
      idp!,
      config.gandalfDomain
    );
    setProvider(provider);
  }, [config.gandalfDomain, customState]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (user?.new_user === true || user?.new_user === 'true') {
      rumService.recordEvent('vibe_created', {
        idp: user.providerName,
        clientId: urlSearchParams.get('client_id'),
        linkingCandidate: Boolean(user.associate_to),
        languageSelected: getInitialLocaleSelection(DEFAULT_LOCALE),
      });
    }
    if (user?.new_user === false || user?.new_user === 'false') {
      rumService.recordEvent('existing_user', {
        idp: user?.providerName,
        clientId: urlSearchParams.get('client_id'),
        linkingCandidate: Boolean(user?.associate_to),
        languageSelected: getInitialLocaleSelection(DEFAULT_LOCALE),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.new_user]);

  useEffect(() => {
    if (!config.enableIdpTypeCheck) return;
    if (!user) return;
    const checkIsELtiIdp = async (user: GandalfUser, config: AppConfig) => {
      const idpDetails = await queryApi(config, user.providerName);
      setIdpDetails(idpDetails);
    };
    checkIsELtiIdp(user, config);
  }, [config, user]);

  const handleContinue = () => {
    const nextFlowIndex = flowIndex + 1;
    metricsPublisher.current.publishCounterMonitor('PostAuthContinue', 1);
    // Are we ready to redirect to LXP auth?
    if (nextFlowIndex === postAuthFlows.length) {
      redirectToLXPAuth(
        config.enableGandalfSession === 'true' &&
          config.gandalfSessionEndpoint &&
          !isStandardProvider(provider!.idp)
          ? providersService.transformNameToIdProvider(
              IdPs.GandalfSession,
              config.gandalfDomain
            )
          : provider!
      );
      setIsRedirecting(true);
    } else {
      setFlowIndex(nextFlowIndex);
    }
  };

  function getLogoutUrl(config: AppConfig) {
    const redirectUrl = `${window.location.origin}${AppURL.RedirectToLogin}`;
    return `https://${config.gandalfDomain}/logout?client_id=${
      config.authClientId
    }&logout_uri=${encodeURIComponent(redirectUrl)}&response_type=code`;
  }

  const handleCancel = () => {
    metricsPublisher.current.publishCounterMonitor('PostAuthCancel', 1);
    const logoutUrl = getLogoutUrl(config);
    const params = new URLSearchParams(window.location.search);
    localStorageAdapter.setItem(URL_PARAMS, params.toString());
    window.location.href = logoutUrl;
  };

  const isAuthenticatedWithProvider = Boolean(user && provider);

  if (error) {
    // eslint-disable-next-line no-console
    console.error(`Error occurred during authN ${error}`);
    if (error instanceof UnauthorizedError) {
      return (
        <SignInUnauthorized
          config={config}
          error={error}
          provider={provider!}
        />
      );
    } else if (error instanceof MissingEmailError) {
      return (
        <MissingEmail config={config} error={error} provider={provider!} />
      );
    }
    return <SignInFailed config={config} error={error} provider={provider!} />;
  }

  if (config.enableIdpTypeCheck === 'true') {
    if (!Boolean(idpDetails)) return null;
  }

  if (isRedirecting) return null;
  if (!isAuthenticatedWithProvider) return null;

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  const AuthFlowComponent = postAuthFlows[flowIndex].component;
  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout currentPostAuthFlow={postAuthFlows[flowIndex].type}>
          <AuthFlowComponent
            user={user!}
            onContinue={handleContinue}
            onCancel={handleCancel}
            config={config}
            error={error ?? undefined}
            setError={setError}
            consentAccepted={consentAccepted}
            setConsetAccepted={setConsentAccepted}
            idpDetails={idpDetails}
          />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default AuthResponse;
