export enum AppURL {
  Login = '/login',
  Logout = '/logout',
  EnterpriseEmailLookup = '/login/enterprise',
  LoginResponse = '/login/response',
  Help = '/help',
  AccountLinkValidate = '/accountlinkvalidate',
  AccountLinkFailed = '/accountlinkfailed',
  ValidatedWrongAccount = '/validatedwrongaccount',
  LogOutValidatedWrongAccount = '/logoutvalidatewrongaccount',
  AccountLinkFAQ = '/accountlinkfaq',
  SignOutToAllowSignIn = '/signouttoallowsignin',
  MultipleLwaAccountLinkSupport = '/multiplelwaaccountlink',
  SignOutToAllowSignInFlowRedirect = '/signouttoallowsignflowredirect',
  OTPInput = '/otp/input',
  OTPChallenge = '/otp/challenge',
  SendVerificationEmail = '/otp/email',
  LwaSignInWarning = '/lwaSignInWarning',
  VerifyEmails = '/verifyEmails',
  LoginVerified = '/loginVerified',
  RedirectToLogin = '/redirectToLogin',
}
