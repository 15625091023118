/**
 * OAuth error status when user is not on the approved list. The message payload contains this status.
 * @constant {string}
 */
export const FORBIDDEN_FAILED_STATUS =
  'PreTokenGeneration failed with error [Forbidden]';

/**
 * OAuth error message suffix when user is not on the approved list.
 * @constant {string}
 */
export const FORBIDDEN_MSG_SUFFIX = 'is not allowlisted';

/**
 * This is the minium distance from the FORBIDDEN_FAILED_STATUS and FORBIDDEN_MSG_SUFFIX,
 * which indicate that there's no email in the error message
 */
export const FORBIDDEN_NO_EMAIL_DIST = 5;

/**
 * URL search query param for the used IDP.
 */
export const IDP_URL_PARAM = 'identity_provider';

/**
 * Eula field to call Gandalf Profile backend.
 */
export const EULA_GANDALF_PROFILE_FIELD = 'eulaAcceptance';

/**
 * Default Eula field name defined from Gandalf
 */
export const DEFAULT_EULA_ATTRIBUTE_NAME = 'eula';

/**
 * This is the value of an accepted EULA
 */
export const EULA_CONFIRMATION = 'true';

/**
 * Time in MS to close logout popup window
 */
export const CLOSE_POPUP_MS = 1000;

/**
 * URL search query param for the redirect uri
 */
export const REDIRECT_URI = 'redirect_uri';

/**
 * Last used token
 */
export const LAST_USED_TOKEN = 'LAST_USED_TOKEN';

/**
 * Last used Gandalf Token
 */
export const LAST_USED_GANDALF_TOKEN = 'LAST_USED_GANDALF_TOKEN';

/**
 * Last used State
 */
export const LAST_USED_STATE = 'LAST_USED_STATE';

/**
 * URL search query param for requiring email verification
 */
export const REQUIRE_EMAIL_VERIFICATION = 'require_email_verification';

/**
 * User merge consented field to call Gandalf Profile backend.
 */
export const USER_MERGE_CONSENTED_GANDALF_PROFILE_FIELD = 'userMergeConsented';
/**
 * URL params key in local storage
 */
export const URL_PARAMS = 'URL_PARAMS';
