import { AppConfig } from '../types/app';
import { AppURL } from '../constants/urls';
import { URL_PARAMS } from '../constants/auth';

const RedirectToLogin = ({ config }: { config: AppConfig }) => {
  const extractedParams = localStorage.getItem(URL_PARAMS);
  window.location.assign(
    `${window.location.origin}${AppURL.Login}?${extractedParams?.toString()}`
  );
  return <></>;
};
export default RedirectToLogin;
