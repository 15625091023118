import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { AppURL } from './constants/urls';
import SignIn from './pages/SignIn';
import Error from './pages/Error';
import PostAccountLinkValidate from './pages/PostAccountLinkValidate';
import ESSOPage from './pages/ESSOPage';
import AuthResponse from './pages/AuthResponse';
import useHtmlLocale from './hooks/useHtmlLocale';
import { AppConfig } from './types/app';
import Help from './pages/Help';
import WithProviders from './components/WithProviders';
import { Logout } from './pages/Logout';
import EmailOTPSignIn from './pages/EmailOTPSignIn';
import AnswerChallenge from './pages/AnswerChallenge';
import AccountLinkFailed from './pages/AccountLinkFailed';
import ValidatedWrongAccount from './components/ValidatedWrongAccount';
import LogOutValidatedWrongAccount from './pages/LogOutValidatedWrongAccount';
import AccountLinkFAQ from './pages/AccountLinkFAQ';
import SignOutToAllowSignIn from './components/SignOutToAllowSignIn';
import MultipleLwaAccountLinkSupport from './components/MultipleLwaAccountLinkSupport';
import SignOutToAllowSignInFlowRedirect from './pages/SignOutToAllowSignInFlowRedirect';
import LwaWarningComponent from './components/LwaWarningComponent';
import PostOTPValidate from './pages/PostOTPValidate';
import SendVerificationEmail from './pages/SendVerificationEmail';
import { SignInVerified } from './pages/SignInVerified';
import PostPage from './components/PostPage';
import RedirectToLogin from './pages/RedirectToLogin';

function App({ config }: { config: AppConfig }) {
  useHtmlLocale();
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <Routes>
        <Route
          path={AppURL.Login}
          element={
            <WithProviders config={config}>
              {({ providers }) => (
                <SignIn config={config} providers={providers} />
              )}
            </WithProviders>
          }
        />
        <Route
          path={AppURL.EnterpriseEmailLookup}
          element={
            <WithProviders config={config}>
              {({ providers }) => (
                <ESSOPage config={config} providers={providers} />
              )}
            </WithProviders>
          }
        />
        <Route
          path={AppURL.SendVerificationEmail}
          element={<SendVerificationEmail config={config} />}
        />
        <Route
          path={AppURL.VerifyEmails}
          element={<PostOTPValidate config={config} />}
        />
        <Route
          path={AppURL.LoginResponse}
          element={<AuthResponse config={config} />}
        />
        <Route path={AppURL.Help} element={<Help config={config} />} />
        <Route path={AppURL.Logout} element={<Logout config={config} />} />
        <Route
          path={`${AppURL.OTPInput}/*`}
          element={<EmailOTPSignIn config={config} />}
        />
        <Route
          path={`${AppURL.OTPChallenge}/*`}
          element={<AnswerChallenge config={config} />}
        />
        <Route
          path={AppURL.AccountLinkValidate}
          element={<PostAccountLinkValidate config={config} />}
        />
        <Route
          path={AppURL.AccountLinkFailed}
          element={<AccountLinkFailed />}
        />
        <Route
          path={AppURL.ValidatedWrongAccount}
          element={<ValidatedWrongAccount config={config} />}
        />
        <Route
          path={AppURL.LogOutValidatedWrongAccount}
          element={<LogOutValidatedWrongAccount config={config} />}
        />
        <Route
          path={AppURL.AccountLinkFAQ}
          element={<AccountLinkFAQ config={config} />}
        />
        <Route
          path={AppURL.RedirectToLogin}
          element={<RedirectToLogin config={config} />}
        />
        <Route
          path={AppURL.SignOutToAllowSignIn}
          element={<SignOutToAllowSignIn config={config} />}
        />
        <Route
          path={AppURL.MultipleLwaAccountLinkSupport}
          element={<MultipleLwaAccountLinkSupport config={config} />}
        />
        <Route
          path={AppURL.SignOutToAllowSignInFlowRedirect}
          element={<SignOutToAllowSignInFlowRedirect config={config} />}
        />
        <Route
          path={AppURL.LwaSignInWarning}
          element={<LwaWarningComponent config={config} />}
        />
        <Route path="/posts" element={<PostPage />} />
        <Route path={AppURL.LoginVerified} element={<SignInVerified />} />
        <Route
          path="*"
          element={<Error config={config} details="Invalid path" />}
        />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
